import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Ensure AOS styles are imported
import $ from 'jquery';

// Component Imports
import HeaderNav from './components/HomePage/Header';
import Home from './components/Home';
import Footer from './components/HomePage/Footer';
import Rooting from './Router';
import Popup from './components/popup'; // Import the Popup component

// SCSS Imports
import './App.scss';
import './components/scss/varibles.scss';

// Utility function for device detection
const getDeviceType = () => {
  const width = window.innerWidth;
  if (width <= 767) return 'mobile';
  if (width >= 768 && width <= 1024) return 'tablet';
  return 'desktop';
};

const App = () => {
  const [showModal, setShowModal] = useState(true); // State for Popup

  useEffect(() => {
    // Initialize AOS animations
    const deviceType = getDeviceType();
    AOS.init({ disable: deviceType === 'mobile' });

    // Log device type for debugging
    console.log(`${deviceType.charAt(0).toUpperCase() + deviceType.slice(1)} detected`);

    // Example: Add device-specific behavior
    if (deviceType === 'tablet') {
      console.log('Tablet-specific logic can be added here.');
    } else if (deviceType === 'desktop') {
      console.log('Desktop-specific logic can be added here.');
    }
  }, []); // Runs once after the component mounts

  return (
    <div className="App">
      <HeaderNav />
      <Rooting />
      <Footer />
      
      {/* Global Popup */}
      {showModal && <Popup onClose={() => setShowModal(false)} />}
    </div>
  );
};

export default App;
