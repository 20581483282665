import React from "react";

const Popup = ({ onClose }) => {
    return (
        <div
            style={{
                position: "fixed",
                bottom: "5%", // Adjusted for better mobile responsiveness
                right: "1%",
                zIndex: 999,
                backgroundColor: "rgba(255, 255, 255, 0.9)",
                borderRadius: "8px",
                padding: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                display: "flex",
                flexDirection: "column", // Stack items vertically on small screens
                alignItems: "center",
                maxWidth: "90vw", // Prevents overflow on small screens
                width: "auto",
            }}
        >
            <button
                onClick={onClose}
                style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    color: "black",
                    fontSize: "16px",
                    background: "none",
                    border: "none",
                    cursor: "pointer",
                }}
            >
                &times;
            </button>

            <img
                src="images/popup.png" // Replace with actual image path
                alt="Stay Secure Stay Resilient"
                style={{
                    width: "100%", // Default width for mobile
                    maxWidth: "300px", // Limits size on larger screens
                    height: "auto",
                    borderRadius: "5px",
                }}
            />
        </div>
    );
};

export default Popup;
