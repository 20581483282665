import React from 'react'
import Breadcrumb from './Breadcrumb';
import Article from './Article';
import ArticleFeature from './ArticleFeature';
import LeftColumn from './Left-common';
import { DAManagmentNav } from '../Data/BreadcrumbData'
import { DAManagmentAD } from '../Data/ArticleData'
import { DAManagmentArticle } from '../Data/ArticleFeatureData';
import { DAMTitle } from '../Data/LeftColmnsData'

const DAManagmentPage = () => {
    return (
        <>
            <div>
                <Breadcrumb  {...DAManagmentNav} />
                <div class="single-service pt-75 pb-75">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-4">
                                <LeftColumn {...DAMTitle} />

                            </div>
                            <div class="col-lg-8">
                                <Article {...DAManagmentAD} />

                                <div className="ap-section-content">
                                    <div className="overview-content">
                                        <h2 className="overview-title">Why Valuesoft Info for Asset Management?</h2>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <ul className="list-style-bullet">
                                                    <li>Real-Time Visibility: Gain 24/7 access to your asset data, enabling smarter decisions and faster responses to changing conditions.</li>
                                                    <li>Predictive Maintenance: Prevent costly breakdowns with advanced analytics and machine learning tools that identify issues before they occur.</li>
                                                    <li>Cost Optimization: Streamline asset utilization, reduce unnecessary expenses, and ensure peak performance for all your resources.</li>
                                                    <li>Powered by Microsoft Azure: Harness the scalability and reliability of Azure for seamless integration, robust analytics, and secure asset management.</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 col-lg-6">
                                                <div className="overview-thumb mb-30">
                                                    <img src="/images/innerpage/analytics-1.png" alt="Microsoft 365 services overview" /> {/* Added alt text */}
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-6">
                                                <div className="ap-content-inner d-flex">
                                                    <div className="ap-content-icon">
                                                        <i className="bi bi-check-circle"></i>
                                                    </div>
                                                    <div className="ap-content-text ml-20">Key Features:</div>
                                                </div>
                                                <div className="ap-content-inner d-flex mt-10">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <ul className="list-style-bullet">
                                                                <li>Comprehensive asset tracking and reporting.</li>
                                                                <li>Data-driven insights to enhance operational efficiency.</li>
                                                                <li>IoT-enabled monitoring for smarter maintenance.</li>
                                                                <li>Scalable solutions tailored to your business size and goals.</li>
                                                                <li>Integration with existing systems for a unified management approach.</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <p className="pt-15 pb-25">
                                        Achieve Operational Excellence: 
                                        With Valuesoft Info, you can ensure your assets are working for you—not the other way around. From reducing downtime to optimizing performance, our solutions help you stay ahead in a competitive landscape.
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </>
    )
}
export default DAManagmentPage;