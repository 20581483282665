
export const BusinessSolutionsArticle = {
    "id": 1,
    "title": "",
    "bodyContent": "We identify the current gaps, understand your goals and make recommendations to help you achieve your desired outcomes. Valuesoft Info Strategic Planning Team serves to align technology efforts in support of business strategies, and focuses on those areas where technology can have the most meaningful impact.",
    "bodyImg": "/images/innerpage/business(2).png",
    "bodyContent3": "  ",
    "bodyContent1": "By identifying the current gaps, understanding your goals, and providing recommendations to help you reach your desired outcomes, we will help you achieve your goals. Valuesoft Info's technology strategies increase revenues, decrease costs, and increase employee satisfaction.",
    "bodyContent2": "In this environment, we understand the pressure to deliver strategic performance as well as tactical capability with fewer resources. In order to understand your organization's challenges. VSI's Strategic Planning Team takes a holistic view of your applications, data, infrastructure, and industry. ",


};
export const itConsultingArticle = {
    "id": 1,
    "title": "All our consultants are fully trained to understand the IT sector and all have first hand IT experience.",
    "bodyContent": " We work with you to fully understand the roles you are recruiting to ensure we provide the right level of skilled IT professionals to meet your needs. We retain highly motivated and qualified technical professionals available for permanent, contract, or temporary placement over the full scope of emergent IT fields, including:   ",
    "bodyImg": "/images/innerpage/consulting(1).png",
    "bodyContent1": " We develop and administer databases, work in digital media, test software & analyze quality, provide technical support, and develop websites.",
    "bodyContent2": " Analysis and integration of enterprise systems, network design & administration, software development, project management, and technical writing.",
   

};

export const HrSolutionsArticle = {
    "id": 1,
    "title": "Human Resources Consulting Services",
    "bodyContent": "The importance of people to the global economy has never been greater. CEOs increasingly rate human resources issues among their top challenges and business priorities. To compete effectively, businesses need strategies for attracting, rewarding, retaining and motivating the best talent in their industry.",
    "bodyImg": "/images/innerpage/hrbp(2).png",
    "bodyContent1": "Our Valuesoft Info benefits administration services help companies of all sizes to deliver better service, mitigate risk and focus on more strategic matters. Whether your business is a growing mid-sized organization or a complex global enterprise, you can trust our solutions to improve the health of your workforce.",
    "bodyContent2": "In addition to cutting costs and improving service to a global work force, many organizations are outsourcing their HR processes to cut costs and create a more strategic function for HR. Outsourcing multiple HR projects to Valuesoft Info creates a more strategic HR function, transforms delivery models, and enhances employee service.",
    "bodyContent3": "Benefits outsourcing, such as retirement and health care administration, is among the major components of our integrated, scalable services. As a provider of HR services and organizations, we have the domain expertise, processes, technology, and quality to help our clients maximize their ROI.",


};

export const ProcurementArticle = {
    "id": 1,
    "title": "At ValueSoft, we believe in the power of synergy.",
    "bodyContent": "Our SCM solutions help our clients achieve greater collaboration among stakeholders. We redefine the supply chain pathway, and boost service levels across diverse verticals - planning and forecasting, strategic sourcing and procurement, and fulfilment and after-sales services. ",
    "bodyImg": "/images/innerpage/analytics(1).png",
    "bodyContent1": "Asset & Wealth Management: Driving improved customer engagements and re-establishing trust with customers  via an enhanced client onboarding, servicing and end-client reporting", 
    
    "bodyContent2": "Banking: Providing banks with the necessary technologies and regulatory/risk management techniques to compete against disruptive market entrants while staying compliant with rapidly changing regulatory and risk requirements",
    
    "bodyContent3": " ",


};

export const  CorporateTrainingArticle = {
    "id": 1,
    "title": "Extensive Experience ",
    "bodyContent": "Our clients are continually impressed with how   Valuesoft Info can focus on and understand the necessary skills of the employees and create tailored classes to generate enthusiasm and commitment. Valuesoft Info learning and development consulting professionals – facilitators and certified professionals  facilitators – serve the following industries: ",
    "bodyImg": "/images/innerpage/cpt(2).png",
    "bodyContent1": " Consulting, Software Technologies, Financial Services, Healthcare, Higher Education,  Industrial,Insurance ,Oil & Gas, Pharmaceutical, Telecommunications",
    "bodyContent2": "  Organizational change, Strategic planning, Project planning, Corporate training, Team building   ",
    "bodyContent3": "  IT Technology Training , Train-the-trainer, Workshop design,  Leadership development,  Executive retreats, Meetings and conferences  ",


};

export const  StaffingAugmentationArticle = {
    "id": 1,
    "title": " Our Staff Augmentation Services Include:",
    
    "bodyImg": "/images/innerpage/Staff_Aug_2.jpg",
   
    "bodyContent1": " Why Choose Valuesoft Info?",
    "bodyContent2": " •	Flexible Engagement Models: Tailor solutions to fit your unique business needs, whether short-term, long-term, or project specific.  •	Global Talent Pool: Access a broad range of highly skilled professionals from various locations. •	Cost-Effective Solutions: Balance quality with competitive pricing, allowing you to maximize efficiency without compromising on talent.  •	Seamless Integration: Our professionals integrate effortlessly into your teams, ensuring smooth collaboration and swift project progress.",
    "bodyContent3": "Partner with Valuesoft Info to enhance your workforce, scale with confidence, and accelerate your business success.",
    
};

export const  OutsourcingArticle = {
    "id": 1,
    "title": "Our Outsourcing Services: ",
    "bodyContent": "•	IT Outsourcing: Boost your tech capabilities with our expert IT teams, providing everything from infrastructure management to software development. •	Business Process Outsourcing (BPO): Streamline essential operations like customer support, data management, and back-office functions while reducing overhead costs. •	Managed Services: Gain access to a full suite of managed services, from monitoring and maintenance to proactive IT support, ensuring smooth, uninterrupted business operations. •	Offshore Outsourcing: Maximize efficiency by outsourcing key tasks to our global team of professionals, delivering high-quality results at competitive rates. •	Custom Outsourcing Solutions: Tailor your outsourcing strategy with a bespoke solution that fits your unique business goals, whether you need to scale fast or improve operational efficiency.",
    "bodyImg": "/images/innerpage/outsourcing-1.png",
    "bodyContent1": " Why Choose Valuesoft Info? ",
    "bodyContent2": "   •	Cost-Efficient: Outsource strategically to reduce costs while maintaining high standards of quality. •	Expertise on Demand: Gain instant access to specialized talent in various fields, from IT to customer service. •	Focus on Core Competencies: Free your internal teams to focus on growth, while we care for your operational needs. •	Global Reach, Local Understanding: Leverage the best of global talent with the advantage of local market knowledge.",
    "bodyContent3": "Partner with Valuesoft Info and unlock the power of outsourcing to accelerate your business success!",


};

export const  BusinessIntelligenceArticle = {
    "id": 1,
    "title": " BI Reporting Solutions    ",
    "bodyContent": "To be the leading provider of comprehensive information technology solutions encompassing consulting, integration and support services. To maintain quality standards on a global parameter. Our developers effortlessly integrate BI solutions into your existing IT infrastructure for: ",
    "bodyImg": "/images/innerpage/analytics-1.png",
    "bodyContent1": "Enablement of ad hoc data querying and report generation.Interactive, Data-intensive visualizations and “what-if” analysis tools  ",
    "bodyContent2": "   Dynamic KPIs using pixel-perfect sharable visualizations, SaaS tools from Cognos, Tableau, SiSense and Pentaho,  Custom dashboard reporting with centralized data repositories  ",
    "bodyContent3": " BI Data Warehouse Solutions : We provide Business Intelligence (BI) data warehouse services, including the engineering of central repositories, to Extract, Transform and Load (ETL) data from disparate platforms and applications. We can seamlessly construct multiple nodes for data aggregation, securing the data clusters safely in the cloud or on-premises databases for data mining,  filtering and cleansing.   ",


};

export const  DAManagmentArticle = {
    "id": 1,
    "title": "Why Valuesoft Info for Asset Management?",
    "bodyContent": "* Real-Time Visibility: Gain 24/7 access to your asset data, enabling smarter decisions and faster responses to changing conditions.<br/> Predictive Maintenance: Prevent costly breakdowns with advanced analytics and machine learning tools that identify issues before they occur.<br/> Cost Optimization: Streamline asset utilization, reduce unnecessary expenses, and ensure peak performance for all your resources.Powered by Microsoft Azure:Harness the scalability and reliability of Azure for seamless integration, robust analytics, and secure asset management ",
    "bodyImg": "/images/innerpage/dam-2.png",
    "bodyImg": "/images/innerpage/analytics-1.png",
    "bodyContent1": " Key Features:  Comprehensive asset tracking and reporting, <br/> Data-driven insights to enhance operational efficiency, <br/> IoT-enabled monitoring for smarter maintenance, <br/> Scalable solutions tailored to your business size and goals, <br/>Integration with existing systems for a unified management approach ",
    "bodyContent2": " Dynamic Indexing: Leverage a truly personalized DAM experience with easily customizable indexing and cataloging tools. We include pre-programmed metadata fields that make sense for your organization and give you the power to create custom fields as you see fit. Our systems can automate the categorization of media types, bandwidth and latency so appropriate codecs can be used during processing.    ",
    "bodyContent3": "  Asset Distribution and Control: Ensure your assets can only be accessed, shared and edited by approved parties with smart permission controls. Our access control modules will help you establish automated release rules for digital assets upon satisfying certain stipulations (including payment processing). We can also integrate Digital Rights Management (DRM) software for tracking the release and possession of rich media.  ",


};

/*
export const   ArVrArticle = {
    "id": 1,
    "title": "How Virtual Reality can help your business?",
    "bodyContent": "Virtual Reality App for Training –Virtual Reality is evolved mainly Training prepare them for events that are complex..",
    "bodyImg": "/images/innerpage/ar-1.png",
    "bodyContent1": "Virtual Reality App for E-commerce –This industry has benefited maximum from  VR technology. Virtual stores provide customers with a virtual experience that can take a tour of the virtual world at any time. Brand/companies in the industry are using AR technology engaging customers in their stores. This is a new way to immerse users deeper into the e-commerce shopping experience and make them learn more about the products  ",
    "bodyContent2": "360 Virtual Tour –360 virtual tours are taking its business prospect to new heights. It offers an immersive way to create an emotional connection with your customers. Previewing any sites online like  360 virtual tours is a new way of content marketing that is adding an important element to business.     ",
    "bodyContent3": "    ",


};
*/

export const   M365Article = {
    "id": 1,
    "title": "Our Microsoft 365 Services:",
    "bodyContent": " •	Microsoft 365 Setup & Migration: Transition to Microsoft 365 with ease! Our experts handle everything from setup to migration, ensuring a smooth, hassle-free switch with zero downtime. •	Customization & Integration: Tailor Microsoft 365 to fit your business needs by integrating it with your existing tools and workflows. We customize everything from SharePoint to Teams, so your team gets the most out of the platform. •	Security & Compliance Solutions: Protect your data with Microsoft 365’s robust security features. We help you configure policies, ensure compliance with industry standards, and keep your sensitive information safe. •	Training & Support: Empower your team with expert training and ongoing support. We provide hands-on sessions to get your staff up to speed with Microsoft 365’s full range of features, maximizing productivity. •	Managed Microsoft 365 Services: Let us manage your Microsoft 365 environment for you. From regular updates to user management and troubleshooting, our team ensures everything runs smoothly while you focus on your business.",
    "bodyImg": "/images/innerpage/ai-2.png",
    "bodyContent1": "Why Choose Valuesoft Info? ",
    "bodyContent2": " •	Seamless Implementation: Experience a smooth transition with zero disruption to your daily operations. <br><br/>•	Expert Guidance: Leverage our in-depth Microsoft 365 expertise to customize and optimize the platform for your business. •	Ongoing Support: Our dedicated team provides continuous support, ensuring your Microsoft 365 environment is always up and running. •	Increased Collaboration & Productivity: Get the most out of Microsoft’s tools for communication, collaboration, and document management.",
    "bodyContent3": " Supercharge your workplace with Valuesoft Info’s Microsoft 365 Services—where productivity meets simplicity! ",


};