import React from "react";
import '../scss/Portfolio.scss';
import { Routes, Route, Outlet, Link, NavLink } from "react-router-dom";

const scrollToTop = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
};

const Portfolio = (refreshPage) => {
    return (
        <section id="work">

            <section className="val-portfolio ">
                <div className="val-portfolio_body">
                    <div className="container">
                        <div className="row ">
                            {/*<div className="col-md-4">
                                <h5 className="primary-white" data-aos="flip-up"> Our Proud Partnership</h5>
                            </div>
                           <div className="col-md-8">
                                <h3 data-aos="fade-up"> We do what we do best. We don't do what we're not good at <span className="primary-orange"> Simple...</span></h3>
                            </div>*/}
                            <div className="col-md-8">
                                <h3 data-aos="fade-up"> Our Strategic <span className="primary-orange"> Partnerships...</span></h3>
                            </div>
                        </div>
                        {/*<div className="row mt-5">
                            <div className="col-md-10 mx-auto val-portfolio_body_card">
                                <div className="row">
                                    <div className="col-6 val-portfolio_body_card_item">
                                        <Link  onClick={scrollToTop} to="/services/business-solutions"   >
                                            <img src={process.env.PUBLIC_URL + '/images/portfolio-1.jpg'} className="mb-4 img-fluid" alt="Value Soft Logo" />
                                            <h4> End-to-End Business Services</h4>
                                        </Link>
                                    </div>
                                    <div className="col-6 val-portfolio_body_card_item">
                                        <Link onClick={scrollToTop} to="/services/technology-consulting"   >
                                            <img src={process.env.PUBLIC_URL + '/images/portfolio-2.jpg'} className="mb-4 img-fluid" alt="Value Soft Logo" />
                                            <h4> IT Platform Services</h4>
                                        </Link>
                                    </div>
                                </div>
                                <div className=" row mt-5">
                                    <div className="col-6 val-portfolio_body_card_item">
                                    <Link onClick={scrollToTop} to="/services/staffing-augmentation"   >
                                        <img src={process.env.PUBLIC_URL + '/images/portfolio-3.jpg'} className="mb-4 img-fluid" alt="Value Soft Logo" />
                                        <h4> Staff Augmentation</h4>
                                        </Link>
                                    </div>
                                    <div className="col-6 val-portfolio_body_card_item">
                                    <Link  onClick={scrollToTop}to="/digital-tech/business-intelligence"   >
                                        <img src={process.env.PUBLIC_URL + '/images/portfolio-4.jpg'} className="mb-4 img-fluid" alt="Value Soft Logo" />
                                        <h4> BI and Analytics</h4>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>*/}


                        <div className="row" style={{ color: "white" }}>

                            <div className="col-md-6 col-lg-6 d-flex align-items-top">
                                <div className="overview-thumb" style={{ marginTop: "20px" }}>
                                    <a href="https://testingroots.com/" target="_blank" rel="noopener noreferrer">
                                        <img
                                            src="images/clients/TestingRoots-Logo.jpg"
                                            alt="Testing Roots"
                                            style={{ width: "100%", height: "auto" }}
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6" >
                                <div className="ap-content-inner d-flex">
                                    {/* <div className="ap-content-icon">
                                        <i className="bi bi-check-circle"></i>
                                    </div>*/}
                                    <div className="ap-content-text mt-10"> We have partnered with Testing Roots, a Saudi Arabia-based software testing company, since 2023. With over 12 years of experience in Saudi Arabia through e-Solutions, Testing Roots has contributed to major banking, telecom, and government projects, including the first end-to-end banking merger migration project in Saudi Arabia for SABB and Alawwal banks. Their expertise spans cards and payments, fintech solutions, AI-enabled automation, performance testing, and Cybersecurity testing.</div>
                                </div>

                                <div className="ap-content-inner d-flex mt-10" style={{ paddingTop: "10px" }}>
                                    <div className="row">
                                        <div className="ap-content-inner d-flex">
                                            <div className="ap-content-icon">
                                                <i className="bi bi-check-circle" style={{ color: "primary-orange" }}></i>
                                            </div>
                                            <div className="ap-content-text ml-20" > Success story of Testing Roots:</div>
                                        </div>
                                        <div className="col-md-12" style={{ paddingTop: "10px" }}>
                                            <div style={{ color: "white" }}>We are a premier testing center of excellence, providing domain and product-based competencies with a skilled and composed testing team. By understanding local cultures and harnessing cutting-edge technologies, we seamlessly integrate into projects, ensuring testing that aligns with your regional trans formative business needs.</div> <br />
                                            <div>Our commitment to world-class test governance ensures consistent, reliable, and top-quality testing services worldwide. At Testing Roots, our dedication to excellence drives us to deliver superior, customized testing solutions tailored to your needs, helping you thrive in the dynamic world of software testing.</div> <br />
                                            <div>"Our mission is to provide bespoke testing services with localized service delivery while adhering to international process standards."</div> <br />
                                            <div>"Our vision is to orchestrate trans formative techno-business solutions for our clients' digital and AI needs, utilizing next-generation testing frameworks."</div> <br />
                                            <div>"Our focus is on rooting processes, standardizing best practices, and accelerating innovation."</div> <br />
                                            <div>"Delivering unwavering quality through advanced testing standards: Testing excellence rooted in regional-specific ethos.”</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
            <section className="val-feature  bg-white">

                <div class="container">
                    <div class="row footer-hr">
                        <div class="col-md-6">
                            <div class="subscribe-section-content d-flex">
                                <div class="subscribe-section-icon">
                                    <i class="bi bi-globe"></i>
                                </div>
                                <div class="subscribe-section-title">
                                    <h5>Sign up for latest updates</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="subscribe-input-field">
                                <form action="#">
                                    <div class="subscribe-mail-box">
                                        <input class="subscribe-mail-box" type="email" placeholder="Enter Your Email..." required />
                                        <button class="subscribe-submit-btn" type="submit" name="submit">Subscribe</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
        </section>
    );
}

export default Portfolio;